





import {Vue, Component, Prop, Emit} from "vue-property-decorator";

import Echarts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/legendScroll'

@Component({
    components: {
        'e-charts': Echarts
    }
})
export default class el_chart extends Vue {
    @Prop({default: '400px'}) width?: number;
    @Prop({default: '300px'}) height?: number;
    @Prop() options!: object;
    created() {

    }
}

